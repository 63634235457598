import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import { Trans } from "gatsby-plugin-react-i18next";
import Button from "./button";

export interface RegisterOverlayProps {
    authCode: string;
    onCloseClick: () => void;
}

export interface RegisterOverlayState {}

export default class RegisterOverlay extends React.Component<
    RegisterOverlayProps,
    RegisterOverlayState
> {
    constructor(props: RegisterOverlayProps) {
        super(props);
        this.state = {};
    }

    public render() {
        return (
            <StaticQuery
                query={graphql`
                    query {
                        site {
                            siteMetadata {
                                appRegisterUrl
                                googleUrl
                                appleUrl
                                googleUrlV2
                                appleUrlV2
                                isV2
                            }
                        }
                    }
                `}
                render={data => (
                    <div className={"register-overlay"}>
                        <div className={"overlay"} />
                        <div className={"outer-container"}>
                            <div className={"container"}>
                                <h1><Trans>registerOverlay.title</Trans></h1>
                                <p><Trans components={{ strong: <strong /> }}>registerOverlay.infoParagraph</Trans></p>

                                <p className={"step step-1"}><Trans components={{ strong: <strong /> }}>registerOverlay.step1</Trans></p>

                                <div className={"buttons"}>
                                    <Button kind="download_google" url={data.site.siteMetadata.isV2 ? data.site.siteMetadata.googleUrlV2 : data.site.siteMetadata.googleUrl}></Button>
                                    <Button kind="download_apple" url={data.site.siteMetadata.isV2 ? data.site.siteMetadata.appleUrlV2 : data.site.siteMetadata.appleUrl}></Button>
                                </div>

                                <p className={"step step-2"}><Trans components={{ strong: <strong /> }}>registerOverlay.step2</Trans></p>

                                <div className={"buttons"}>
                                    <Button kind="register_auth" url={data.site.siteMetadata.appRegisterUrl + this.props.authCode}></Button>
                                </div>

                                <p className={"step grayed"}>
                                    <Trans components={{ strong: <strong /> }}>overlayManualAuth.description1</Trans>
                                    <br />
                                    <br />
                                    <Trans components={{ strong: <strong /> }}>overlayManualAuth.description2</Trans>
                                    <br />
                                    <span className={"step"}>
                                        <strong className={"step-num"}>
                                            1.
                                        </strong>{" "}
                                        <Trans components={{ strong: <strong /> }}>overlayManualAuth.step1</Trans>
                                    </span>
                                    <span className={"step"}>
                                        <strong className={"step-num"}>
                                            2.
                                        </strong>{" "}
                                        <Trans components={{ strong: <strong /> }}>overlayManualAuth.step2</Trans>
                                        <code
                                            className={"auth-code"}
                                            onClick={(event) => {
                                                let node = (event.target as HTMLElement)
                                                    .childNodes[0];

                                                let range = document.createRange();
                                                range.setStart(node, 0);
                                                range.setEnd(node, 12);

                                                let selection = window.getSelection();
                                                if (selection) {
                                                    selection.removeAllRanges();
                                                    selection.addRange(range);
                                                }
                                            }}
                                        >
                                            {this.props.authCode}
                                        </code>
                                    </span>
                                    <span className={"step"}>
                                        <strong className={"step-num"}>
                                            3.
                                        </strong>{" "}
                                        <Trans components={{ strong: <strong /> }}>overlayManualAuth.step2</Trans>
                                    </span>
                                </p>
                                <div className={"bottom"}>
                                    <a
                                        href="#"
                                        className={"close"}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            if (this.props.onCloseClick) {
                                                this.props.onCloseClick();
                                            }
                                        }}
                                    >
                                        <span />
                                        <Trans>registerOverlay.close</Trans>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            />
        );
    }
}
