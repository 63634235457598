import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";

import Layout from "./layout";
import Img from "gatsby-image";
import RegisterOverlay from "./register-overlay";
import { WindowLocation } from "@reach/router";
import { I18nextContext, Trans } from "gatsby-plugin-react-i18next";
import MigrateOverlay from "./migrate-overlay";
import Button from "./button";

export interface IndexPageInnerProps {
    location: WindowLocation;
}

export interface IndexPageInnerState {
    authCode: string | null;
    migrateAuthCode: string | null;
}

export default class IndexPageInner extends React.Component<
    IndexPageInnerProps,
    IndexPageInnerState
> {
    constructor(props: IndexPageInnerProps) {
        super(props);

        let authCode: string | null = null;
        let migrateAuthCode: string | null = null;

        let match = this.props.location.search.match(/^\?([a-zA-Z0-9]{12})$/);
        if (match) {
            authCode = match[1];
        }

        let matchMigrate = this.props.location.search.match(/^\?m([a-zA-Z0-9]{12})$/);
        if (matchMigrate) {
            migrateAuthCode = matchMigrate[1];
        }

        this.state = {
            authCode: authCode,
            migrateAuthCode: migrateAuthCode,
        };
    }

    public render() {
        return (
                <StaticQuery
                    query={graphql`
                        query {
                            phones: file(relativePath: { eq: "phones.png" }) {
                                childImageSharp {
                                    fluid(maxHeight: 551) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                            phonesV2: file(relativePath: { eq: "phonesV2.png" }) {
                                childImageSharp {
                                    fluid(maxHeight: 551) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                            site {
                                siteMetadata {
                                    googleUrl
                                    appleUrl
                                    googleUrlV2
                                    appleUrlV2
                                    isV2
                                }
                            }
                        }
                    `}
                    render={data => (
                        <>
                            <div className={"ovarlay-holder"}>
                                {this.state.authCode ? (
                                    <RegisterOverlay
                                        authCode={this.state.authCode}
                                        onCloseClick={() => {
                                            this.setState({ authCode: null });
                                            if (
                                                typeof window !== "undefined" &&
                                                window.history &&
                                                window.history.replaceState &&
                                                window.document
                                            ) {
                                                window.history.replaceState(
                                                    {},
                                                    window.document.title,
                                                    location.pathname
                                                );
                                            }
                                        }}
                                    />
                                ) : null}
                                {this.state.migrateAuthCode ? (
                                    <MigrateOverlay
                                        authCode={this.state.migrateAuthCode}
                                        onCloseClick={() => {
                                            this.setState({ migrateAuthCode: null });
                                            if (
                                                typeof window !== "undefined" &&
                                                window.history &&
                                                window.history.replaceState &&
                                                window.document
                                            ) {
                                                window.history.replaceState(
                                                    {},
                                                    window.document.title,
                                                    location.pathname
                                                );
                                            }
                                        }}
                                    />
                                ) : null}
                            </div>
                            <div className={"main-block"}>
                                <Img
                                    fluid={data.site.siteMetadata.isV2 ? data.phonesV2.childImageSharp.fluid : data.phones.childImageSharp.fluid}
                                    className={"phones"}
                                />
                                <div className={"text"}>
                                    <h1><Trans>mainPage.title</Trans></h1>
                                    <p><Trans>mainPage.paragraph1</Trans></p>
                                    <p><Trans>mainPage.paragraph2</Trans></p>
                                    <h3><Trans>mainPage.downloadAt</Trans></h3>
                                    <div className={"downloads"}>
                                        <Button kind="download_google" url={data.site.siteMetadata.isV2 ? data.site.siteMetadata.googleUrlV2 : data.site.siteMetadata.googleUrl}></Button>
                                        <Button kind="download_apple" url={data.site.siteMetadata.isV2 ? data.site.siteMetadata.appleUrlV2 : data.site.siteMetadata.appleUrl}></Button>
                                    </div>
                                </div>
                                <div className={"clear"} />
                            </div>
                        </>
                    )}
                />
        );
    }
}
