import { Link, StaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Img from "gatsby-image";
import { I18nextContext, Trans } from "gatsby-plugin-react-i18next";

export interface ButtonProps {
    url: string
    kind: 'download_apple' | 'download_google' | 'register_auth'
}

const Button = ({ url, kind }) => (
    <StaticQuery
        query={graphql`
            query {
                download_apple: file(
                    relativePath: { eq: "download_apple.png" }
                ) {
                    childImageSharp {
                        fixed(height: 51) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                download_google: file(
                    relativePath: { eq: "download_google.png" }
                ) {
                    childImageSharp {
                        fixed(height: 51) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                register_auth: file(
                    relativePath: { eq: "register_auth.png" }
                ) {
                    childImageSharp {
                        fixed(height: 51) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
        render={data => (
            <a
                href={url}
                target={"_blank"}
                className={`translated_button kind_${kind} lang_${React.useContext(I18nextContext).language}`}
            >
                <Img
                    fixed={data[kind].childImageSharp.fixed}
                />
                <span className="translation"><Trans>{`buttons.${kind}`}</Trans></span>
            </a>
        )}
    />
);

export default Button;
