import React from 'react';
import {Link, Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import IndexPageInner from '../components/index-page-inner';

const IndexPage = ({ location }) => {
  return (
    <Layout>
      <IndexPageInner location={location}/>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
